// eslint-disable @typescript-eslint/no-unsafe-assignment @typescript-eslint/no-unsafe-return @typescript-eslint/no-explicit-any

export const sortObjectByKey = <T extends object>(object: T): T => {
  const localObj: T = Object.keys(object)
    .sort((aString, bString) =>
      aString.localeCompare(bString, undefined, {
        numeric: true,
        sensitivity: 'base',
      })
    )
    .reduce((acc, key) => {
      acc[key] = (object as any)[key]
      return acc as T
    }, {} as any)

  return localObj
}

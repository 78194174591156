import { Elements } from '@stripe/react-stripe-js'
import { loadStripe, Appearance } from '@stripe/stripe-js'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { ClockLoader } from 'react-spinners'

import { StripeForm } from './stripeForm'
import { CreateOrderResType, CreatePaymentBodyType } from '../../types'
import { useCart } from '../../utils/contexts'

const stripePromise = loadStripe(
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_STRIPE_SANDBOX_CLIENT_ID ?? ''
    : process.env.REACT_APP_STRIPE_PROD_CLIENT_ID ?? ''
)

const appearance: Appearance = {
  theme: 'stripe',
  variables: {
    colorPrimary: '#1A0001',
    colorSuccess: '#28a745',
    colorDanger: '#dc3545',
    colorText: '#212529',
    colorWarning: '#ffc107',
  },
}

export const StripeElements = ({
  isPartialPayment,
  bookingFee = 0,
  donation = 0,
  collectRemainingPayment = false,
}: {
  isPartialPayment: boolean
  bookingFee?: number
  donation?: number
  collectRemainingPayment?: boolean
}): JSX.Element => {
  const { appliedCoupon, shippingAddressId } = useCart()
  const [clientSecret, setClientSecret] = useState<string>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    const createStripePaymentIntent = async () => {
      try {
        // Seatsio Hold Token for held seats
        const { holdToken } = JSON.parse(
          sessionStorage.getItem('seatsio') || '{}'
        )
        const createBodyObj: CreatePaymentBodyType = {
          coupan: appliedCoupon,
          partial_payment: isPartialPayment,
          booking_fee: bookingFee || 0,
          donation: donation || 0,
          shipping_address_id: shippingAddressId,
          shipping_type: 'standard',
          payment_provider: 'stripe',
        }

        if (holdToken) {
          createBodyObj.hold_token = holdToken
        }

        const { data: response } = await axios.post<CreateOrderResType>(
          '/payments/create',
          JSON.stringify(createBodyObj)
        )

        setClientSecret(response.response.client_secret)
      } catch (error) {
        console.log('error')
      } finally {
        setIsLoading(false)
      }
    }

    void createStripePaymentIntent()
  }, [])

  if (isLoading) {
    return (
      <div className="m-3">
        <ClockLoader
          color="#D62C5F"
          loading
          size={75}
          aria-label="Loading Spinner"
        />
      </div>
    )
  }

  return clientSecret ? (
    <Elements stripe={stripePromise} options={{ clientSecret, appearance }}>
      <StripeForm />
    </Elements>
  ) : (
    <>Unable to show payment methods. Please try again later.</>
  )
}

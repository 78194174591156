import { useModal } from '@ebay/nice-modal-react'
import { useEffect, useState } from 'react'
import axios, { AxiosError } from 'axios'
import { toast } from 'react-toastify'
import { Container } from 'react-bootstrap'
import ClockLoader from 'react-spinners/ClockLoader'
import { useNavigate } from 'react-router-dom'

import { OrderSuccessModal } from '../../../components/OrderSuccessModal'
import { PaymentApproveResType } from '../../../types'
import { PaymentApproveErrorType } from '../../../components/PaypalButtonsWrapper/paypalButtonsWrapper.types'
import { useAuthentication } from '../../../utils/contexts'
import { PageTitle } from '../../../components'

import './checkoutSuccess.scss'

export const CheckoutSuccess = (): JSX.Element => {
  const [status, setStatus] = useState('')
  const modal = useModal(OrderSuccessModal)
  const { token } = useAuthentication()
  const navigate = useNavigate()

  useEffect(() => {
    const getPaymentIntent = async (provider_id: string) => {
      if (token) {
        try {
          const { data: response } = await axios.post<PaymentApproveResType>(
            '/payments/capture',
            {
              order_id: provider_id,
              payment_provider: 'stripe',
            },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )

          if (response.status === 'succeeded') {
            setStatus(response.status)
            await modal.show({ orderId: response.order_id })
          }
        } catch (error) {
          if (
            ((error as AxiosError).response?.data as PaymentApproveErrorType)
              ?.message
          ) {
            toast.error(
              `${
                (
                  (error as AxiosError).response
                    ?.data as PaymentApproveErrorType
                )?.message
              }`
            )
          } else {
            toast.error('Transaction not approved. Please try again later.')
          }
          navigate('/', { replace: true })
        }
      }
    }

    const paymentIntentId = new URLSearchParams(window.location.search).get(
      'payment_intent'
    )

    if (paymentIntentId && token) {
      void getPaymentIntent(paymentIntentId)
    }
  }, [token])

  return (
    <>
      <PageTitle title="Payment" />
      <Container className="checkout-result d-flex align-items-center justify-content-center">
        {status !== 'succeeded' && (
          <ClockLoader
            color="#D62C5F"
            loading
            size={150}
            aria-label="Loading Spinner"
          />
        )}
      </Container>
    </>
  )
}

import { Fragment, useEffect, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import axios from 'axios'
import { toast } from 'react-toastify'
import { useModal } from '@ebay/nice-modal-react'

import {
  CreateOrderResType,
  PaymentProvider,
  PrintifyProductType,
  ProductType,
  TicketType,
} from '../../../types'
import { useCart, useDeviceType, useLoading } from '../../../utils/contexts'
import { PageTitle, PaypalButtonsWrapper } from '../../../components'
import {
  CartItemProductTypeEnum,
  CartItemType,
  CartItemTypeEnum,
} from '../../../utils/contexts/CartContext/cartContext.type'
import './checkoutSummary.scss'
import {
  formatPrice,
  getQuantityPrice,
  sortObjectByKey,
} from '../../../utils/helpers'
import { OrderSuccessModal } from '../../../components/OrderSuccessModal'
import { StripeElements } from '../../../components/StripeElementsWrapper'

type NewCartType = CartItemType & {
  displayPrice: string
  displayQuantity: number
}

type FilteredListType = {
  [key: string]: NewCartType[]
}

type CheckoutProps = {
  applyVat?: boolean
}

const PaymentOptions = [
  { name: 'Full Payment', value: '1' },
  { name: 'Partial Payment', value: '2' },
]

export const CheckoutSummary = ({ applyVat = false }: CheckoutProps) => {
  const {
    cart,
    afterCouponTotal,
    shippingCost,
    appliedCoupon,
    setAppliedCoupon,
  } = useCart()
  const navigate = useNavigate()
  const location = useLocation()
  const modal = useModal(OrderSuccessModal)
  const { isMobile } = useDeviceType()
  const { isLoading: isCreatingOrder, setIsLoading } = useLoading()

  const [sortedItems, setSortedItems] = useState<FilteredListType>({})
  const [subTotalPrice, setSubTotalPrice] = useState<number>(0)
  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [donationAmount, setDonationAmount] = useState<number>(5)
  const [bookingFee, setBookingFee] = useState<number>(0)
  const [isPartialPayment, setIsPartialPayment] = useState(false)
  const [paymentOption, setPaymentOption] = useState(PaymentOptions[0].value)
  const [paymentProvider, setPaymentProvider] = useState<
    PaymentProvider | undefined
  >()

  useEffect(() => {
    if (location.state) {
      if (location.state.applyVat) {
        setDonationAmount(0)
      } else {
        setDonationAmount(location.state.donationAmount as number)
      }

      if (location.state.isPartialPayment) {
        setIsPartialPayment(location.state.isPartialPayment as boolean)
        setPaymentOption(PaymentOptions[1].value)
      }

      if (location.state.bookingFee) {
        setBookingFee(location.state.bookingFee as number)
      }

      if (location.state.paymentProvider) {
        setPaymentProvider(location.state.paymentProvider as PaymentProvider)
      }
    } else {
      navigate('/checkout/overview')
    }
  }, [location])

  const setCartItems = () => {
    if (cart) {
      if (cart?.items.length === 0) {
        navigate('/events', { replace: true })
      }
      setSubTotalPrice(() => 0)
      const filteredList: FilteredListType = {}
      let appliedCouponInCart = ''

      cart.items.forEach((cartItem) => {
        if (
          cartItem.item_type === CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT &&
          (cartItem.item as PrintifyProductType).product_type ===
            CartItemProductTypeEnum.PRODUCT_TYPE_PRINTIFY &&
          !shippingCost
        ) {
          navigate('/checkout/shipping-address')
        }

        if (cartItem.discount_coupan) {
          appliedCouponInCart = cartItem.discount_coupan
        }

        const cartItemPrice =
          cartItem.item_type === CartItemTypeEnum.CART_ITEM_TYPE_TICKET
            ? cartItem.total
            : Number.parseFloat(`${cartItem.price_per_unit}`) *
              cartItem.quantity

        setSubTotalPrice((subTotalPrice) => subTotalPrice + cartItemPrice)

        const { newQuantity, newPricePerUnit } = getQuantityPrice(cartItem)

        const localCartItem: NewCartType = {
          ...cartItem,
          displayPrice:
            cartItem.item_type === CartItemTypeEnum.CART_ITEM_TYPE_TICKET
              ? `${cartItem.item.price}`
              : `${newPricePerUnit}`,
          displayQuantity: newQuantity,
        }

        let key = ''

        if (cartItem.item_type === CartItemTypeEnum.CART_ITEM_TYPE_TICKET) {
          key = (cartItem.item as TicketType).event?.name || 'Tickets'
        } else if (
          (cartItem.item as ProductType).category.name === 'Printify'
        ) {
          key = 'Merchandise'
        } else {
          key = (cartItem.item as ProductType).category.name
        }

        if (filteredList[key]) {
          filteredList[key].push(localCartItem)
        } else {
          filteredList[key] = [localCartItem]
        }
      })

      // This will sort the list based on Name of the ticket
      const sortObject = sortObjectByKey<FilteredListType>(filteredList)

      setSortedItems({ ...sortObject })

      if (appliedCouponInCart.length) {
        setAppliedCoupon(appliedCouponInCart)
      }
    }
  }

  useEffect(() => {
    setCartItems()
  }, [cart])

  useEffect(() => {
    setCartItems()
  }, [])

  useEffect(() => {
    let total = donationAmount || 0

    if (shippingCost && shippingCost.standard) {
      total += shippingCost.standard * 1.2
    }

    if (applyVat || location.state?.applyVat) {
      total += subTotalPrice * 1.2
    } else if (afterCouponTotal !== undefined) {
      total += afterCouponTotal
    } else {
      total += subTotalPrice
    }

    setTotalPrice(Math.round(total * 100) / 100)
  }, [
    applyVat,
    subTotalPrice,
    donationAmount,
    shippingCost,
    location,
    afterCouponTotal,
  ])

  useEffect(() => {
    if (cart && isPartialPayment) {
      let subtotal = 0
      cart.items.forEach((cartItem) => {
        if (paymentOption === PaymentOptions[0].value) {
          subtotal +=
            Number.parseFloat(`${cartItem.price_per_unit}`) * cartItem.quantity
        } else if (
          cartItem.item_type === CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT &&
          (cartItem.item as ProductType).product_type ===
            CartItemProductTypeEnum.PRODUCT_TYPE_STALL
        ) {
          const discountPercent =
            (cartItem.item as ProductType).category.id === 4 ? 0.4 : 0.6
          subtotal +=
            Number.parseFloat(`${cartItem.price_per_unit}`) *
            cartItem.quantity *
            discountPercent
        } else {
          subtotal +=
            Number.parseFloat(`${cartItem.price_per_unit}`) * cartItem.quantity
        }
      })

      setSubTotalPrice(subtotal)
    }
  }, [cart, paymentOption, isPartialPayment])

  const getPartialPayment = (cartItem: NewCartType): number => {
    if (
      cartItem.item_type === CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT &&
      (cartItem.item as ProductType).product_type ===
        CartItemProductTypeEnum.PRODUCT_TYPE_STALL
    ) {
      const discountPercent =
        (cartItem.item as ProductType).category.id === 4 ? 0.4 : 0.6

      return (
        Number.parseFloat(`${cartItem.price_per_unit}`) *
        cartItem.quantity *
        discountPercent
      )
    }
    return Number.parseFloat(`${cartItem.price_per_unit}`) * cartItem.quantity
  }

  // This is only being used if coupon is applied that gives 100% discount and the cart total is 0.
  const createOrder = async () => {
    setIsLoading(true)
    try {
      const { data: createPaymentRes } = await axios.post<CreateOrderResType>(
        '/payments/create',
        JSON.stringify({
          coupan: appliedCoupon,
          booking_fee: 0,
          donation: 0,
          payment_provider: 'coupan',
        })
      )

      if (createPaymentRes && createPaymentRes.order_id) {
        setIsLoading(false)
        await modal.show({ orderId: createPaymentRes.order_id })
      }
    } catch {
      toast.error('Error creating the order. Please try again later.')
      setIsLoading(false)
    }
  }

  return (
    <>
      <PageTitle title="Payment" />
      <Container className="mt-4 mt-xl-5 pt-4 pt-xl-5 mb-3 checkout">
        <Row>
          <Col md={2} />
          <Col md={8}>
            <Card className="border-2 border-primary rounded-3 p-3">
              <Row className="border-bottom border-dark pb-2 mx-0">
                <Col
                  xs={4}
                  sm={4}
                  md={5}
                  className={`p-0 ${isMobile ? 'fs-8' : 'fs-6'}`}
                >
                  Ticket
                </Col>
                <Col
                  xs={2}
                  sm={2}
                  md={2}
                  className={`text-center p-0 ${isMobile ? 'fs-8' : 'fs-6'}`}
                >
                  Price
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={2}
                  className={`text-end p-0 ${isMobile ? 'fs-8' : 'fs-6'}`}
                >
                  Quantity
                </Col>
                <Col
                  xs={3}
                  sm={3}
                  md={3}
                  className={`text-end p-0 ${isMobile ? 'fs-8' : 'fs-6'}`}
                >
                  Total
                </Col>
              </Row>
              {Object.entries(sortedItems).map(([key, cartItems]) => (
                <Fragment key={key}>
                  <Row className="mx-0 mt-2">
                    <Col md={12} className={`${isMobile ? 'p-0' : ''}`}>
                      <p className={`fw-bold ${isMobile ? 'fs-6' : 'fs-5'}`}>
                        {key}
                      </p>
                    </Col>
                  </Row>
                  <Row className="border-dark border-bottom border-opacity-25 mb-3 pb-2 mx-0">
                    {cartItems.map((cartItem) => (
                      <Fragment key={cartItem.id}>
                        <Col
                          xs={4}
                          sm={4}
                          md={5}
                          className={`fs-8 d-flex align-items-center pb-2 ${
                            isMobile ? 'px-0' : ''
                          }`}
                        >
                          {cartItem.item.name}{' '}
                          {cartItem.object_id
                            ? ` (${cartItem.object_id.join(', ')})`
                            : ''}
                        </Col>
                        <Col
                          xs={2}
                          sm={2}
                          md={2}
                          className="fw-bolder d-flex align-items-center justify-content-center pb-2"
                        >
                          £
                          {formatPrice(
                            Number.parseFloat(cartItem.displayPrice)
                          )}
                        </Col>
                        <Col
                          xs={3}
                          sm={3}
                          md={2}
                          className="d-flex align-items-center justify-content-center pb-2"
                        >
                          <p className="fs-7">{cartItem.displayQuantity}</p>
                        </Col>
                        <Col
                          xs={3}
                          sm={3}
                          md={3}
                          className={`d-flex align-items-center justify-content-end pb-2 ${
                            isMobile ? 'px-0' : ''
                          }`}
                        >
                          {cartItem.discount_coupan !== null ? (
                            <span className="text-decoration-line-through pe-3">
                              £
                              {formatPrice(
                                cartItem.price_per_unit * cartItem.quantity
                              )}
                            </span>
                          ) : (
                            <></>
                          )}
                          {paymentOption === PaymentOptions[1].value &&
                          cartItem.item_type ===
                            CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT &&
                          (cartItem.item as ProductType).product_type ===
                            CartItemProductTypeEnum.PRODUCT_TYPE_STALL ? (
                            <span className="text-decoration-line-through pe-3">
                              £
                              {formatPrice(
                                cartItem.price_per_unit * cartItem.quantity
                              )}
                            </span>
                          ) : (
                            <></>
                          )}
                          <span className="fw-bolder">
                            £
                            {paymentOption === PaymentOptions[1].value &&
                            cartItem.item_type ===
                              CartItemTypeEnum.CART_ITEM_TYPE_PRODUCT &&
                            (cartItem.item as ProductType).product_type ===
                              CartItemProductTypeEnum.PRODUCT_TYPE_STALL
                              ? getPartialPayment(cartItem)
                              : formatPrice(cartItem.total)}
                          </span>
                        </Col>
                      </Fragment>
                    ))}
                  </Row>
                </Fragment>
              ))}
            </Card>
          </Col>
          <Col md={2} />
        </Row>
        <Row>
          <Col md={2} />
          <Col md={8}>
            <Card className="border-2 border-primary rounded-3 p-2 mt-4">
              {!(applyVat || location.state?.applyVat) ? (
                <Row className="m-0 mb-2">
                  <Col
                    xs={8}
                    sm={8}
                    md={8}
                    className="fw-bold d-flex align-items-center"
                  >
                    Donation Amount
                  </Col>
                  <Col xs={4} sm={4} md={4} className="text-end fw-semibold">
                    £ {formatPrice(donationAmount)}
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              <Row className="mx-0 pb-2">
                <Col xs={8} sm={8} md={8} className="fw-bold">
                  Subtotal
                </Col>
                <Col xs={4} sm={4} md={4} className="text-end fw-semibold">
                  £ {formatPrice(subTotalPrice)}
                </Col>
              </Row>
              {!!shippingCost && shippingCost.standard && (
                <Row className="mx-0 pb-2">
                  <Col md={6} className="fw-bold">
                    Shipping Cost
                  </Col>
                  <Col md={6} className="text-end fw-semibold">
                    £ {formatPrice(shippingCost.standard)}
                  </Col>
                </Row>
              )}
              {applyVat || location.state?.applyVat ? (
                <Row className="mx-0 pb-2">
                  <Col xs={8} sm={8} md={8} className="fw-bold">
                    VAT 20%
                  </Col>
                  <Col xs={4} sm={4} md={4} className="text-end fw-semibold">
                    £{' '}
                    {formatPrice(
                      Math.round(
                        (subTotalPrice + (shippingCost?.standard || 0)) *
                          0.2 *
                          100
                      ) / 100
                    )}
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              {bookingFee > 0 && (
                <Row className="mx-0">
                  <Col xs={8} sm={8} md={8} className="fw-bold">
                    Booking Fee
                  </Col>
                  <Col xs={4} sm={4} md={4} className="text-end fw-semibold">
                    £ {formatPrice(bookingFee)}
                  </Col>
                </Row>
              )}
              <Row className="mx-0 mt-4 mb-2 pt-3 border-top border-dark border-opacity-25">
                <Col xs={8} sm={8} md={8} className="fw-bold">
                  Total
                </Col>
                <Col xs={4} sm={4} md={4} className="text-end fw-semibold">
                  £ {formatPrice(totalPrice + bookingFee)}
                </Col>
              </Row>
              {totalPrice > 0 ? (
                <Row className="mx-0 mt-4 mb-2">
                  <Col className="d-flex justify-content-center">
                    {paymentProvider === 'paypal' && (
                      <PaypalButtonsWrapper
                        isPartialPayment={
                          paymentOption === PaymentOptions[1].value
                        }
                        bookingFee={bookingFee}
                        donation={donationAmount}
                      />
                    )}{' '}
                    {paymentProvider === 'stripe' && (
                      <StripeElements
                        isPartialPayment={
                          paymentOption === PaymentOptions[1].value
                        }
                        bookingFee={bookingFee}
                        donation={donationAmount}
                      />
                    )}
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              {(afterCouponTotal !== undefined && afterCouponTotal === 0) ||
              totalPrice === 0 ? (
                <Row className="mx-0 mt-4 mb-2">
                  <Col className="d-flex justify-content-center">
                    <Button
                      variant="primary"
                      className="rounded-pill mt-3 w-100"
                      onClick={() => {
                        void createOrder()
                      }}
                      disabled={isCreatingOrder}
                    >
                      {isCreatingOrder ? 'Creating order . . .' : 'Buy Now'}
                    </Button>
                  </Col>
                </Row>
              ) : (
                <></>
              )}
            </Card>
          </Col>
          <Col md={2} />
        </Row>
      </Container>
    </>
  )
}

import { FormEvent } from 'react'
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js'
import { toast } from 'react-toastify'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { HOST_URL } from '../../utils/constants'

export const StripeForm = () => {
  const stripe = useStripe()
  const elements = useElements()

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault()

    if (stripe && elements) {
      const result = await stripe.confirmPayment({
        elements,
        confirmParams: {
          return_url: `${HOST_URL}/checkout/success`,
        },
      })

      if (result.error) {
        toast.error(
          `${result.error.decline_code ?? result.error.decline_code}: ${
            result.error.message
          }`
        )
      }
    }
  }

  return (
    <Row className="w-75 w-md-100">
      <Col>
        <form onSubmit={(event) => void handleSubmit(event)}>
          <PaymentElement />
          <Button disabled={!stripe} type="submit" className="mt-4 w-100">
            Submit
          </Button>
        </form>
      </Col>
    </Row>
  )
}
